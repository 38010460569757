<template>
  <div v-if="property">
    <!-- Edit form -->
    <FormProperty :baseProperty="property" @submit-requested="submitRequested"></FormProperty>
    <hr>
     <!-- Status picker and add module -->
    <h2 class="flex-grow-1 secondary-text-color font-weight-bold">Gerir módulos</h2>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="col-lg-6 m-0 p-0 text-left"> 
        <button v-bind:class="{'font-weight-bold': status == ACTIVE_STATUS}" class="status-button" @click="status = ACTIVE_STATUS">Ativos</button> <span  v-bind:class="{'font-weight-bold': status == ACTIVE_STATUS}">({{activeCount}})</span> | <button v-bind:class="{'font-weight-bold': status == DISABLED_STATUS}" class="status-button" href="#" @click="status = DISABLED_STATUS">Arquivo</button> <span v-bind:class="{'font-weight-bold': status == DISABLED_STATUS}">({{disabledCount}})</span>
      </div>
        <button id="add-button" type="button" @click="showModulePopUp = true; selectedModule = null" class="btn color-button text-light d-flex align-items-center">
          <span class="d-none d-lg-block">Criar módulo</span>
          <span class="d-block d-lg-none font-weight-bold">+</span>
        </button>
    </div>
    <CTableWrapper class="mt-4" ref="table" hover striped :caption="caption" :items="items" :actions="actions" :pages="pages" :fields="fields" clickable-rows @row-clicked="rowClicked" @action-clicked="actionClicked" @update-requested="updateItemsRequested" :sorter='{ external: false, resetable: false }' :loading="loading" paginationSubtitle="Imóveis por Página">
    </CTableWrapper>
    <!-- Pop ups -->
    <ManageModulePopUp v-if="showModulePopUp" :propertyId="property.id" :baseModule="selectedModule" @closeConfirmRequested="showModulePopUp = null" v-model="showModulePopUp"></ManageModulePopUp>
  </div>
</template>
 
<script>
import FormProperty from '../forms/FormProperty.vue'
import ManageModulePopUp from "@/controls/popups/ManageModulePopUp.vue";
import { mapState, mapActions } from 'vuex'
import CTableWrapper from '@/controls/PaginatedTable.vue'
import inputGetModule from "@/classes/inputGetModule";
import EventBus from '@/events/bus.js';

const DISABLED_STATUS = false;
const ACTIVE_STATUS = true;


export default {  
  components: { FormProperty, ManageModulePopUp, CTableWrapper },
  data () {
    return {
      status: ACTIVE_STATUS,
      caption: ('Módulos'),
      currentPage: 1,
      loading: false,
      itemsPerPage: localStorage.itemsPerPage ?? 10,     
      fields: [
        { key: 'name', label: ('Módulo'), _classes: 'font-weight-bold', sortable: false },
      ],      
      actions: [
        { key: 'edit', image: 'iconEditWhite.png', imageBig: 'iconEdit.png' },
        { key: 'disable', image: 'iconFolderWhite.png', imageBig: 'iconFolder.png' }
      ],
      activeTab: 0,
      property: this.$route.params.property,
      selectedModule: null,
      showModulePopUp: null,
      DISABLED_STATUS: DISABLED_STATUS,
      ACTIVE_STATUS: ACTIVE_STATUS,
    }
  },
  computed:
  {
    ...mapState('modules', ['listOutput']),
    ...mapState('modules', ['processTask']),

    modules: function()
    {
      return this.listOutput && this.listOutput.data && this.listOutput.data.modules ? this.listOutput.data.modules : null;
    },
    activeCount: function()
    {
      return this.listOutput && this.listOutput.data ? this.listOutput.data.active : 0;
    },
    disabledCount: function()
    {
      return this.listOutput && this.listOutput.data ? this.listOutput.data.inactive : 0;
    },
    items: function()
    {
      var result = [];
        if(this.modules && this.modules.length)
        {
          result = this.modules;
        }
      return result;
    },
    pages: function()
    {
      return this.listOutput ? this.listOutput.totalPages : 1;
    },
  },
  mounted: function()
  {
    if(!this.$route.params.property || !this.$route.params.modules)
    {
      this.$router.push({ name: 'ViewProperty', params: { property: this.property } });
    } 
    this.updateItemsRequested(1, this.itemsPerPage);
  },
  methods: {
    ...mapActions('properties', ['editProperty']),
    ...mapActions('modules', ['getList']),
    ...mapActions('modules', ['changeVisibility']),

    submitRequested (property) {
      this.editProperty(property);
    },
    rowClicked: function(item)
    {
      this.showModulePopUp = true;
      this.actionClicked(item, {key: 'edit'});
    },
    actionClicked(item, action){
      switch(action.key)
      {
        case 'edit':
        {
          this.showModulePopUp = true
          this.selectedModule = item;
          break;
        }
        case 'disable':
        {
          EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende arquivar o imóvel: '${item.name}'?`, primaryCallback: () =>
          {
            this.changeVisibility(item.id);
          }, secondaryButton: "Cancelar"});
          break;
        }
        case 'enable':
        {
          EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende ativar o imóvel: '${item.name}'?`, primaryCallback: () =>
          {
            this.changeVisibility(item.id);
          }, secondaryButton: "Cancelar"});
          break;
        }
        default:
        {
            throw "The provided action is not supported!"; 
        }
      }
    },
    updateItemsRequested(currentPage, itemsPerPage)
    {
      this.loading = true;

      this.currentPage = currentPage;
      this.itemsPerPage = itemsPerPage;
      const propertyName = "id";
      const sorterValue = 1; // Descending

      var input = new inputGetModule(currentPage,itemsPerPage, sorterValue, propertyName, this.property.id, this.status);
      this.getList(input);
    
    }
  },
  watch:
  {
    listOutput: function()
    {
      this.loading = false;
    },
    showModulePopUp:function(val){
      if(!val){
        this.selectedModule = null;
      }   
    },
    processTask: function(val)
    {
      if(val !== null)
      {
        this.loading = false;
        this.selectedModule = null;
        this.updateItemsRequested(this.currentPage, this.itemsPerPage);
      }
    },
    status: function (val) {
      // Update items
      switch(val) {

      case ACTIVE_STATUS:
          {
              this.actions = [];        
              this.actions.push({ key: 'edit', image: 'iconEditWhite.png', imageBig: 'iconEdit.png'});
              this.actions.push({ key: 'disable', image: 'iconFolderWhite.png', imageBig: 'iconFolder.png'});
              break;
          }
      case DISABLED_STATUS:
          {
              this.actions = [];
              this.actions.push({ key: 'edit', image: 'iconEditWhite.png', imageBig: 'iconEdit.png'});
              this.actions.push({ key: 'enable', image: 'iconUnarchiveWhite.png', imageBig: 'iconUnarchive.png' });
              break;
          }
          default:
          {
              throw "The provided status is not supported!"; 
          }
      }
      
      // Update items
      this.$refs.table.reset();
      this.updateItemsRequested(1, this.itemsPerPage);       
    },
  }
}
</script> 
 
<style scoped>
>>>.modal-container{
  width: 500px;
  min-width: 300px;
  height: auto;
  background-color: #fff;
  transition: all .3s ease;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 #303942;
  padding: 0;
}
</style> 