export default class inputGetModule
{
  constructor( pageNumber, pageSize, orderBy, propertyName, propertyId, active) 
  {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.orderBy = orderBy;
    this.propertyName = propertyName;
    this.propertyId = propertyId;
    this.active = active;
  }    
}