<template>
    <BaseModal v-if="showModal" @detailsClosed="closeConfirmRequested">
        <div class="modal-body-container">
            <!-- Title -->
            <h4 v-if="baseModule" class="text-center mt-4">Editar nome do módulo</h4>  
            <h4 v-else class="text-center mt-4">Criar módulo</h4>  
            <div class="container d-flex flex-column">          
                <!-- Name -->
                <div class="col-12 mt-3">
                    <label class="label-properties">Nome do Módulo</label>
                    <input type="text" v-model="name" class="form-control" :class="{ 'is-invalid': submitted && hasError('Name') }"/>
                    <div v-if="submitted && hasError('Name')" class="invalid-feedback">
                        <span v-if="!$v.name.required">É necessário o Nome do Módulo</span>
                        <span v-if="errors.Name">{{errors.Name}}</span>
                    </div>
                </div>
                <!-- Action button + loading -->  
                <CSpinner v-if="loading" variant="success" small class="align-self-end mt-5"></CSpinner>
                <CButton v-else type="button" color="primary" class="btn align-self-end text-light mt-5" @click="submitClicked" id="save-button">Guardar</CButton>
            </div>
        </div>
    </BaseModal>
</template>

<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import { mapState, mapActions } from 'vuex'
import BaseModal from './BaseModal.vue';
import { required } from 'vuelidate/lib/validators'

export default {
    extends: BaseModal,
    components: {BaseModal},
    validations: {
        name: { required },
        
    },
    props:{    
        baseModule:
        {
            type: Object,
            default: null
        },
        propertyId: Number,
    },
    data()
    {
        return {
            submitted: false,
            name: this.baseModule ? this.baseModule.name : null
        }
    },
    methods:{
        ...mapActions('modules', ['createModule']),
        ...mapActions('modules', ['updateModule']),
        closeConfirmRequested()
        {       
            this.$emit("closeConfirmRequested");
        },
        hasError(fieldName)
        {
            return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
        submitClicked(){
            if(!this.propertyId)
            {
                throw "The property id is not defined!";
            }

            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            if(this.baseModule){
                const params = {id: this.baseModule.id, name: this.name, propertyId: this.propertyId};
                this.updateModule(params); 
            }
            else
            {
                const params = {name: this.name, propertyId: this.propertyId};
                this.createModule(params);      
            }

        },
    },
    computed:
    {
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output,
        }),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
        ...mapState('modules', ['processTask']),
        loading: function()
        {
            return this.processTask == null;
        }
    },
    watch: 
    {
        processTask: function(val)
        {
            if(val)
            {
                this.$emit("closeConfirmRequested");
            }
        }
    }
}
</script>

<style scoped>
    .modal-body-container
    {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        border-radius: 10px;    
        min-height: 200px;
    }
</style>